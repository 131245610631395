import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (storedCartItems) {
      // Remove items with a date older than the current day
      const currentDate = moment().format('YYYY-MM-DD');
      const currentTime = moment().format('HH:mm');
      const updatedCartItems = storedCartItems.filter(
        (item) =>
          moment(item.date).format('YYYY-MM-DD') > currentDate ||
          (moment(item.date).format('YYYY-MM-DD') === currentDate &&
            item.start >= currentTime)
      );
      setCartItems(updatedCartItems);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    const index = cartItems.findIndex(
      (cartItem) =>
        cartItem.courtId === item.courtId &&
        cartItem.timeId === item.timeId &&
        cartItem.sport === item.sport &&
        cartItem.date === item.date
    );
    if (index === -1) {
      setCartItems([...cartItems, { ...item, selected: true }]);
    } else {
      const newCartItems = [...cartItems];
      newCartItems.splice(index, 1);
      setCartItems(newCartItems);
    }
  };

  const removeCartItem = (index) => {
    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);
    setCartItems(newCartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  };

  return (
    <CartContext.Provider
      value={{ cartItems, addToCart, removeCartItem, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
