import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { CartContext } from '../context/CartContext';

const TimeSlotButton = ({ timeSlot }) => {
  const { addToCart, cartItems } = useContext(CartContext);

  const handleClick = () => {
    addToCart({ ...timeSlot });
  };

  const isSelected = cartItems.some(
    (cartItem) =>
      cartItem.courtId === timeSlot.courtId &&
      cartItem.timeId === timeSlot.timeId &&
      cartItem.sport === timeSlot.sport &&
      cartItem.date === timeSlot.date &&
      cartItem.selected
  );

  return (
    <Button
      sx={{
        outline: 'none',
        '&:hover': {
          backgroundColor: '#3db5ff',
          color: '#fff',
        },
      }}
      variant={isSelected ? 'contained' : 'outlined'}
      onClick={handleClick}>
      {Number(timeSlot.price)} €
    </Button>
  );
};

export default TimeSlotButton;
