import React from 'react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/lt';
import moment from 'moment';
import { Box } from '@mui/material';

const Calendar = ({ selectedDate, onChange }) => {
  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='lt'>
      <Box
        sx={{
          width: { xs: '340px', sm: '420px' },
          padding: { xs: '0 10px', sm: '0 50px' },
          mt: '20px',
        }}>
        <Box
          sx={{
            width: '320px',
            backgroundColor: '#fff',
            opacity: 0.9,
            height: 'fit-content',
            borderRadius: '5px',
          }}>
          <DateCalendar
            value={selectedDate}
            onChange={handleDateChange}
            open
            minDate={moment()}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default Calendar;
