import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Rezervacija from './pages/Rezervacija';
import Kontaktai from './pages/Kontaktai';
import Navbar from './components/NavBar';
import Krepselis from './pages/Krepselis';
import Footer from './pages/Footer';
import Login from './pages/Login';
import PrivateRoute from './services/privateRoute';
import Uzsakymai from './pages/Uzsakymai';
import AdminPrices from './pages/AdminPrices';
import TimeTable from './pages/TimeTable';

const App = () => (
  <div className='App'>
    <div className='page-container'>
      <div className='content-wrapper'>
        <Navbar />
        <Routes>
          <Route path='/' element={<Rezervacija />} />
          <Route path='/kontaktai' element={<Kontaktai />} />
          <Route path='/krepselis' element={<Krepselis />} />
          <Route path='/login' element={<Login />} />
          <Route
            path='/uzsakymai'
            element={
              <PrivateRoute>
                <Uzsakymai />
              </PrivateRoute>
            }
          />
          <Route
            path='/kortu-kainos'
            element={
              <PrivateRoute>
                <AdminPrices />
              </PrivateRoute>
            }
          />
          <Route
            path='/kortu-laikai'
            element={
              <PrivateRoute>
                <TimeTable />
              </PrivateRoute>
            }
          />
        </Routes>
        <Footer />
      </div>
    </div>
  </div>
);

export default App;
