import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

const Kontaktai = () => {
  const [location, setLocation] = useState(null);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: 'Parko g. 16, LT-35190, Panevėžys, Lietuva' }, (results, status) => {
      if (status === 'OK') {
        setLocation(results[0].geometry.location);
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  }, []);

  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8, pt: '80px' }}>
      <Container maxWidth='md'>
        <Typography
          sx={{
            typography: { xs: 'h4', sm: 'h3' },
          }}
          align='center'
          gutterBottom
        >
          Kontaktai
        </Typography>
        <Grid container spacing={4} sx={{ mt: { xs: 2, sm: 4 } }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: '#fff', p: 3 }}>
              <Typography variant='h4' gutterBottom>
                Adresas
              </Typography>
              <Typography variant='body1' gutterBottom>
                Parko g. 16, LT-35190, Panevėžys
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: '#fff', p: 3 }}>
              <Typography variant='h4' gutterBottom>
                Telefonas
              </Typography>
              <Typography variant='body1' gutterBottom>
                <a
                  style={{
                    color: '#000',
                  }}
                  href='tel:+37068510820'
                >
                  +370 685 10820
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: '#fff', p: 3 }}>
              <Typography variant='h4' gutterBottom>
                El. paštas
              </Typography>
              <Typography variant='body1' gutterBottom>
                <a
                  style={{
                    color: '#000',
                  }}
                  href='mailto:tpetrosaitis@gmail.com'
                >
                  tpetrosaitis@gmail.com
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: '#fff', p: 3 }}>
              <Typography variant='h4' gutterBottom>
                Darbo laikas
              </Typography>
              <Typography variant='body1' gutterBottom>
                Pirmadienis - Sekmadienis: 07:00 - 23:00
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: '#fff', p: 3 }}>
              <Typography variant='h4' gutterBottom>
                Padelio treneris
              </Typography>
              <Typography variant='body1' gutterBottom>
                Edvinas Paulavičius
              </Typography>
              <Typography variant='body1' gutterBottom>
                <a
                  style={{
                    color: '#000',
                  }}
                  href='tel:+37068099698'
                >
                  +370 680 99698
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ bgcolor: '#fff', p: 3 }}>
              <Typography variant='h4' gutterBottom>
                Kineziterapeutas
              </Typography>
              <Typography variant='body1' gutterBottom>
                Matas Šimonis
              </Typography>
              <Typography variant='body1' gutterBottom>
                <a
                  style={{
                    color: '#000',
                  }}
                  href='tel:+37063664342'
                >
                  +370 636 64342
                </a>{' '}
                <a
                  style={{
                    color: '#000',
                  }}
                  href='mailto:simonis.kinezi@gmail.com'
                >
                  simonis.kinezi@gmail.com
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ bgcolor: '#fff', p: 2 }}>
              {location && (
                <GoogleMap mapContainerStyle={mapContainerStyle} center={location} zoom={15} apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <Marker position={location} />
                </GoogleMap>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Kontaktai;
