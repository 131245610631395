import React, { useRef, useState } from 'react';
import Calendar from '../components/Calendar';
import { Box, Button, Typography, Container, Grid, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PadelTable from '../components/PadelTable';
import moment from 'moment';
import VolleyballTable from '../components/VolleyballTable';
import volleyball from '../assets/images/volleyball.jpg';
import padel2 from '../assets/images/padel2.jpg';
import beachIcon from '../assets/images/beachIcon.png';
import volleyIcon from '../assets/images/volleyIcon.png';
import beachtennis from '../assets/images/beachtennis.jpg';
import BeachTennisTable from '../components/BeachTennisTable';
import padelCourt from '../assets/images/padelCourt.png';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import voleybal from '../assets/images/voleybal.jpg';
import beachtenis from '../assets/images/beachtenis.jpg';
import padel1 from '../assets/images/padel1.jpg';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: '5px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: 'auto',
  },
}));

const Rezervacija = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [selectedDate, setSelectedDate] = useState(moment());
  const [currentSport, setCurrentSport] = useState('padel');
  const reservationRef = useRef(null);

  const handleChangeView = (sport) => {
    setCurrentSport(sport);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formattedDate = selectedDate.format('YYYY-MM-DD');

  return (
    <ThemeProvider theme={theme}>
      <Box
        id='#reservation'
        ref={reservationRef}
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          pt: '80px',
          margin: { xs: '0 auto', sm: '0 auto' },
          backgroundImage: `url(${
            currentSport === 'padel' ? padel2 : currentSport === 'volleyball' ? volleyball : currentSport === 'beachTennis' ? beachtennis : ''
          })`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', sm: '100%' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            mt: { xs: 2, sm: 4 },
          }}
        >
          <Button
            sx={{
              mr: { xs: 0, sm: 2 },
              mb: { xs: 1, sm: 0 },
              backgroundColor: '#3020e2',
              color: '#fff',
              opacity: 1,
              height: { xs: '80px', sm: '160px' },
              width: { xs: '100%', sm: '203px' },
              border: currentSport === 'padel' ? '3px solid #fff' : '',
              '&.Mui-disabled': {
                backgroundColor: '#156596',
                color: '#fff',
                opacity: 0.95,
              },
              '&:hover': {
                backgroundColor: '#156596',
                color: '#fff',
                opacity: 0.8,
                border: '3px solid #fff',
              },
            }}
            onClick={() => handleChangeView('padel')}
            disabled={currentSport === 'padel'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row-reverse', sm: 'column' },
                alignItems: 'center',
                justifyContent: 'space-between',
                width: { xs: '90%', sm: 'auto' },
              }}
            >
              <img src={padelCourt} alt='padel' width={64} height={64} />
              <Typography
                variant='h5'
                sx={{
                  fontWeight: 'bold',
                  color: '#fff',
                  ml: { xs: 2, sm: 0 },
                  mt: { xs: 0, sm: 1 },
                }}
              >
                Padelis
              </Typography>
            </Box>
          </Button>
          <Button
            sx={{
              backgroundColor: '#ECCC52',
              color: '#fff',
              opacity: 1,
              height: { xs: '80px', sm: '160px' },
              width: { xs: '100%', sm: '203px' },
              border: currentSport === 'volleyball' ? '3px solid #fff' : '',
              '&.Mui-disabled': {
                backgroundColor: '#ECCC52',
                color: '#fff',
                opacity: 0.95,
              },
              '&:hover': {
                backgroundColor: '#ECCC52',
                color: '#fff',
                opacity: 0.8,
                border: '3px solid #fff',
              },
            }}
            onClick={() => handleChangeView('volleyball')}
            disabled={currentSport === 'volleyball'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row-reverse', sm: 'column' },
                alignItems: 'center',
                justifyContent: 'space-between',
                width: { xs: '90%', sm: 'auto' },
              }}
            >
              <img src={volleyIcon} alt='volleyball' width={64} height={64} />
              <Typography
                variant='h5'
                sx={{
                  fontWeight: 'bold',
                  color: '#fff',
                  ml: { xs: 2, sm: 0 },
                  mt: { xs: 0, sm: 1 },
                }}
              >
                Tinklinis
              </Typography>
            </Box>
          </Button>
          <Button
            sx={{
              mt: { xs: 1, sm: 0 },
              ml: { xs: 0, sm: 2 },
              backgroundColor: '#D6891E',
              color: '#fff',
              opacity: 1,
              height: { xs: '80px', sm: '160px' },
              width: { xs: '100%', sm: '203px' },
              border: currentSport === 'beachTennis' ? '3px solid #fff' : '',
              '&.Mui-disabled': {
                backgroundColor: '#D6891E',
                color: '#fff',
                opacity: 0.95,
              },
              '&:hover': {
                backgroundColor: '#D6891E',
                color: '#fff',
                opacity: 0.8,
                border: '3px solid #fff',
              },
            }}
            onClick={() => handleChangeView('beachTennis')}
            disabled={currentSport === 'beachTennis'}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row-reverse', sm: 'column' },
                alignItems: 'center',
                justifyContent: 'space-between',
                width: { xs: '90%', sm: 'auto' },
              }}
            >
              <img src={beachIcon} alt='beachtennis' width={64} height={64} />
              <Typography
                variant='h5'
                sx={{
                  fontWeight: 'bold',
                  color: '#fff',
                  ml: { xs: 2, sm: 0 },
                  textAlign: { xs: 'left', sm: 'center' },
                  mt: { xs: 0, sm: 1 },
                }}
              >
                Paplūdimio tenisas
              </Typography>
            </Box>
          </Button>
        </Box>
        <Box
          sx={{
            width: { xs: '95%', sm: '100%' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
          }}
        >
          <Calendar value={selectedDate} onChange={handleDateChange} />
          <Box
            sx={{
              width: { xs: '100%', sm: 'auto' },
              padding: { xs: '0 10px', sm: '0 20px' },
              mt: '20px',
              ml: { xs: '', sm: '20px' },
            }}
          >
            {currentSport === 'padel' && <PadelTable date={formattedDate} view={currentSport} />}
            {currentSport === 'volleyball' && <VolleyballTable date={formattedDate} view={currentSport} />}
            {currentSport === 'beachTennis' && <BeachTennisTable date={formattedDate} view={currentSport} />}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          bgcolor: '#f5f5f5',
          py: 8,
          pt: '80px',
        }}
      >
        <Container maxWidth='md'>
          <Typography
            sx={{
              typography: { xs: 'h4', sm: 'h3' },
            }}
            align='center'
            gutterBottom
          >
            Aukštaitijos padelio klubas
          </Typography>
          <Grid
            container
            spacing={4}
            alignItems='center'
            sx={{
              mt: { xs: 2, sm: 4 },
            }}
          >
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant='h5' gutterBottom>
                  Padelis
                </Typography>
                <Typography variant='body1' gutterBottom>
                  Katik baigėme įrengti penkis, visiškai naujai pastatytus lauko padelio kortus. Tuo mes labai džiaugiamės ir kviečiame visus išbandyti šią
                  sporto šaką. Tai puikus būdas praleisti laiką su draugais ir šeima. Norintiems išbandyti padelį, galima rezervuoti padelio kortą ir smagiai
                  praleisti laiką gryname ore!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Image src={padel1} alt='Tennis Court' />
            </Grid>
          </Grid>
          <Grid container spacing={4} alignItems='center' sx={{ mt: { xs: 2, sm: 4 } }}>
            {!isMobile && (
              <Grid item xs={12} md={6}>
                <Image src={voleybal} alt='Tennis Court' />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant='h5' gutterBottom>
                  Tinklinis
                </Typography>
                <Typography variant='body1' gutterBottom>
                  Taip pat katik įrengėme tris, visiškai naujas lauko tinklinio aikšteles. Tuo mes labai džiaugiamės ir kviečiame visus išbandyti šią sporto
                  šaką. Tai puikus būdas praleisti laiką su draugais ir šeima. Norintiems pažaisti tinklinį, galima rezervuoti aikštelę ir smagiai praleisti
                  laiką gryname ore!
                </Typography>
              </Box>
            </Grid>
            {isMobile && (
              <Grid item xs={12} md={6}>
                <Image src={voleybal} alt='Tennis Court' />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={4}
            alignItems='center'
            sx={{
              mt: { xs: 2, sm: 4 },
            }}
          >
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant='h5' gutterBottom>
                  Paplūdimio tenisas
                </Typography>
                <Typography variant='body1' gutterBottom>
                  Katik baigėme įrengti tris, visiškai naujai pastatytus paplūdimio teniso kortus. Tuo mes labai džiaugiamės ir kviečiame visus išbandyti šią
                  sporto šaką. Tai puikus būdas praleisti laiką su draugais ir šeima. Norintiems išbandyti paplūdimio tenisą, galima rezervuoti kortą ir smagiai
                  praleisti laiką gryname ore!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Image src={beachtenis} alt='Tennis Court' />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
            <Button
              sx={{
                width: { xs: '100%', sm: '50%' },
                bgcolor: '#042684',
                color: '#fff',
                height: { xs: 'auto', sm: 'auto' },
                pb: 0,
                pt: 4,
                opacity: 0.9,
                '&:hover': {
                  backgroundColor: '#156596',
                  color: '#fff',
                  opacity: 0.8,
                },
              }}
              onClick={() => reservationRef.current.scrollIntoView({ behavior: 'smooth' })}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '& > img': {
                    width: { xs: '100%', sm: 'auto' },
                    height: { xs: '100%', sm: 'auto' },
                  },
                }}
              >
                <Typography variant='h6' gutterBottom>
                  Aikštelių rezervacija
                </Typography>
                <img src={padelCourt} alt='Tennis Court' />
              </Box>
            </Button>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Rezervacija;
