import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button } from '@mui/material';
import TimeSlotButton from './TimeSlotButton';
import moment from 'moment';
import { getCourts, getPadelBookings, getTimeTable } from '../services/API';
import { timesUntil17 } from '../constants/timeTable';

const StyledTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    text-align: center;
    width: 150px;

    @media (max-width: 600px) {
      font-size: 12px;
      min-width: 97px;
      padding: 12px;
      text-overflow: ellipsis;
    }
  }
`;

const TableWrapper = styled(TableContainer)`
  && {
    overflow-x: auto;
    width: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    opacity: 0.9;
    margin-bottom: 20px;
  }
`;

const FixedColumnCell = styled(TableCell)`
  && {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;

    @media (max-width: 600px) {
      font-size: 12px;
      padding: 12px;
    }
  }
`;

const PadelTable = ({ date, view }) => {
  const currentTime = moment().format('HH:mm');
  const currentDay = moment().format('YYYY-MM-DD');
  const [timeTable, setTimeTable] = useState([]);
  const [filteredTimeTable, setFilteredTimeTable] = useState([]);
  const [courts, setCourts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [bookings, setBookings] = useState([]);

  const isStartTimeUntil17 = (startTime) => {
    return timesUntil17.some((time) => time.start === startTime);
  };

  useEffect(() => {
    if (date === currentDay) {
      const filtered = timeTable.filter((time) => time.start > currentTime);
      setFilteredTimeTable(filtered);
    } else {
      setFilteredTimeTable(timeTable);
    }
  }, [date, currentDay, currentTime, timeTable]);

  useEffect(() => {
    const fetchCourts = async () => {
      const timeTable = await getTimeTable();
      setTimeTable(timeTable);
      const courts = await getCourts(date, 'Padelis');
      setCourts(courts);
      const bookings = await getPadelBookings();
      setBookings(bookings);
      setLoaded(true);
    };
    fetchCourts();
  }, [view, date]);

  const getAvailability = (courtId, date, id, start, end, duration) => {
    const court = courts.find((court) => court.courtId === courtId);
    if (!court) {
      return 'Invalid court';
    }
    const courtBookings = bookings.filter((booking) => booking.courtId === courtId && booking.date === date && booking.end > start && booking.start < end);
    if (courtBookings.length === 0) {
      return (
        <TimeSlotButton
          timeSlot={{
            courtId,
            date,
            courtName: court.courtName,
            price: isStartTimeUntil17(start) ? court.priceUntil17 : court.priceAfter17,
            timeId: id,
            start,
            end,
            duration,
            sport: court.sport,
          }}
        />
      );
    } else {
      return (
        <Button
          variant='outlined'
          disabled
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#fff',
              color: '#156596',
            },
          }}
        >
          X
        </Button>
      );
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <TableWrapper component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <FixedColumnCell>Padelis</FixedColumnCell>
            {courts.map((court) => (
              <StyledTableCell key={court.courtId}>{court.courtName}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTimeTable.map((time) => (
            <TableRow key={time.start}>
              <FixedColumnCell>{`${time.start} - ${time.end}`}</FixedColumnCell>
              {courts.map((court) => (
                <TableCell sx={{ textAlign: 'center', padding: { xs: '12px', sm: '16px' } }} key={court.courtId}>
                  {getAvailability(court.courtId, date, time.id, time.start, time.end, time.duration, court.sport)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default PadelTable;
