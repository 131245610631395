import { toastSuccess, toastError } from './helpers';
import 'react-toastify/dist/ReactToastify.css';

const loginURL = `${process.env.REACT_APP_BACKEND_URL}/auth/login`;
const adminBookingsURL = `${process.env.REACT_APP_BACKEND_URL}/admin/bookings`;
const adminCourtsURL = `${process.env.REACT_APP_BACKEND_URL}/admin/courts`;
const adminTimeTableURL = `${process.env.REACT_APP_BACKEND_URL}/admin/timetable`;
const timeTableURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/timetable`;
const courtsURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/courts-info`;
const padelBookingsURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/padel-bookings`;
const beachBookingsURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/beach-bookings`;
const allBookingsURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/all-bookings`;
const postBookingURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/bookings`;
const updateBookingURL = `${process.env.REACT_APP_BACKEND_URL}/bookings/update-booking`;

export const loginAsync = async (username, password) => {
  try {
    const response = await fetch(loginURL, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    const data = await response.json();
    if (data.status === 'SUCCESS') {
      toastSuccess('Sėkmingai prisijungėte');
      window.localStorage.setItem('token', data.token);
      return data;
    } else {
      toastError(data.msg);
    }
  } catch (error) {
    toastError(error);
  }
};

export const getAdminBookings = async (token) => {
  try {
    const response = await fetch(adminBookingsURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAdminCourts = async (token) => {
  try {
    const response = await fetch(adminCourtsURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCourtPrice = async (
  token,
  selectedCourt,
  weekdayPriceUntil17,
  weekdayPriceAfter17,
  weekendPriceUntil17,
  weekendPriceAfter17,
  selectedSport
) => {
  try {
    const response = await fetch(`${adminCourtsURL}`, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        courtId: selectedCourt,
        weekdayPriceUntil17: weekdayPriceUntil17,
        weekdayPriceAfter17: weekdayPriceAfter17,
        weekendPriceUntil17: weekendPriceUntil17,
        weekendPriceAfter17: weekendPriceAfter17,
        sport: selectedSport,
      }),
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      toastSuccess('Aikštelės kaina atnaujinta');
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTimeTable = async () => {
  try {
    const response = await fetch(timeTableURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAdminTimeTable = async (token) => {
  try {
    const response = await fetch(adminTimeTableURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateTimeTable = async (token, times) => {
  try {
    const response = await fetch(adminTimeTableURL, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ times: times }),
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      toastSuccess('Aikštelių laikai atnaujinti');
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCourts = async (date, sport) => {
  try {
    const response = await fetch(`${courtsURL}/${date}/${sport}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPadelBookings = async () => {
  try {
    const response = await fetch(padelBookingsURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBeachBookings = async () => {
  try {
    const response = await fetch(beachBookingsURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllBookings = async () => {
  try {
    const response = await fetch(allBookingsURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();

    if (data.err) {
      toastError(data.err);
    } else {
      return data.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const postBooking = async (booking) => {
  try {
    const response = await fetch(postBookingURL, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(booking),
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      toastSuccess('Rezervacija sėkminga, patvirtinimo laiškas buvo išsiųstas į jūsų el.paštą!');
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateBooking = async (token, booking, bookingId) => {
  try {
    const response = await fetch(`${adminBookingsURL}/${bookingId}`, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(booking),
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      toastSuccess('Rezervacija atnaujinta');
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteBooking = async (token, bookingId) => {
  try {
    const response = await fetch(`${adminBookingsURL}/${bookingId}`, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.err) {
      toastError(data.err);
    } else {
      toastSuccess('Rezervacija atšaukta');
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};
