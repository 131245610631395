import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { getAdminTimeTable, updateTimeTable } from '../services/API';
import { getTokenFromStorage } from '../services/helpers';
import { ToastContainer } from 'react-toastify';

const StyledPaper = styled(Paper)`
  && {
    padding: 16px;
  }
`;

const StyledTableCell = styled(TableCell)`
  && {
    text-align: center;
  }
`;

const StyledHeadCell = styled(TableCell)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const TimeTable = () => {
  const [times, setTimes] = useState([]);

  useEffect(() => {
    const fetchTimes = async () => {
      const token = getTokenFromStorage();
      const times = await getAdminTimeTable(token);
      setTimes(times);
    };
    fetchTimes();
  }, []);

  const handleToggleActive = (event, time) => {
    const updatedTime = { ...time, isTimeActive: event.target.checked ? 1 : 0 };
    setTimes((prevTimes) =>
      prevTimes.map((prevTime) =>
        prevTime.id === time.id ? updatedTime : prevTime
      )
    );
  };

  const handleSubmit = async () => {
    const token = getTokenFromStorage();
    await updateTimeTable(token, times);
    const timeTable = await getAdminTimeTable();
    setTimes(timeTable);
  };

  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8, pt: '80px' }}>
      <Container maxWidth='md'>
        <ToastContainer
          position='top-center'
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Typography
          sx={{
            typography: { xs: 'h4', sm: 'h3' },
          }}
          align='center'
          gutterBottom>
          Aikštelių laikai
        </Typography>
        <StyledPaper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledHeadCell>ID</StyledHeadCell>
                  <StyledHeadCell>Pradžia</StyledHeadCell>
                  <StyledHeadCell>Pabaiga</StyledHeadCell>
                  <StyledHeadCell>Trukmė (min)</StyledHeadCell>
                  <StyledHeadCell>Aktyvus</StyledHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {times.map((time) => (
                  <TableRow key={time.id}>
                    <StyledTableCell>{time.id}</StyledTableCell>
                    <StyledTableCell>{time.start}</StyledTableCell>
                    <StyledTableCell>{time.end}</StyledTableCell>
                    <StyledTableCell>{time.duration}</StyledTableCell>
                    <StyledTableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!time.isTimeActive}
                            onChange={(event) =>
                              handleToggleActive(event, time)
                            }
                            name='active'
                            color='primary'
                          />
                        }
                        label={time.isTimeActive ? 'Taip' : 'Ne'}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 2 }}>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}>
                  Išsaugoti pakeitimus
                </Button>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
      </Container>
    </Box>
  );
};

export default TimeTable;
