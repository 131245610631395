import React, { useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo.png';
import { CartContext } from '../context/CartContext';
import { getUserRoleFromToken } from '../services/helpers';

const StyledAppBar = styled(AppBar)`
  && {
    background-color: #fff;
    color: #000;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid transparent;
  &:hover {
    opacity: 0.5;
  }
`;

const MobileLink = styled(Link)`
  text-decoration: none;
  color: #3db5ff;
  border-bottom: 2px solid transparent;
  &:hover {
    opacity: 0.5;
  }
`;

const ActiveLink = styled(Link)`
  text-decoration: none;
  color: #3db5ff;
  border-bottom: 2px solid #3db5ff;
  &:hover {
    opacity: 0.5;
  }
`;

const drawerWidth = 240;
const navmenu = [
  { page: 'Užsakymai', url: '/uzsakymai', role: 'admin' },
  { page: 'Kortų kainos', url: '/kortu-kainos', role: 'admin' },
  { page: 'Kortų laikai', url: '/kortu-laikai', role: 'admin' },
  { page: 'Kortų rezervacija', url: '/', role: 'user' },
  { page: 'Kontaktai', url: '/kontaktai', role: 'user' },
];

const Navbar = (props) => {
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { cartItems } = useContext(CartContext);
  const userRole = getUserRoleFromToken();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/', { replace: true });
  };

  const currentPath = useLocation().pathname;

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <List>
        <IconButton
          onClick={() => {
            navigate('/');
            setMobileOpen(!mobileOpen);
          }}
          sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <img src={logo} alt='logo' width={40} height={40} />
          <Typography
            component='div'
            sx={{
              ml: '10px',
              alignItems: 'center',
              display: { xs: 'flex', sm: 'none' },
              color: '#3db5ff',
              fontSize: { xs: '0.8rem', sm: '1rem' },
            }}>
            Aukštaitijos padelio klubas
          </Typography>
        </IconButton>
        {navmenu.map((item, index) => {
          if (item.role === 'admin' && userRole !== 'admin') {
            return null;
          }
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                sx={{ textAlign: 'center', color: 'primary.main' }}
                onClick={handleDrawerToggle}>
                {currentPath !== item.url && (
                  <MobileLink to={item.url}>
                    <ListItemText primary={item.page} />
                  </MobileLink>
                )}
                {currentPath === item.url && (
                  <ActiveLink to={item.url}>
                    <ListItemText primary={item.page} />
                  </ActiveLink>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
        {userRole === 'admin' && (
          <IconButton
            onClick={() => handleSignOut()}
            sx={{
              ml: '10px',
              alignItems: 'center',
              display: { xs: 'flex', sm: 'none' },
              color: '#3db5ff',
              fontSize: { xs: '0.8rem', sm: '1rem' },
            }}>
            <ListItemText primary='Atsijungti' />
          </IconButton>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const totalPrice = cartItems.reduce(
    (total, item) => total + Number(item.price),
    0
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledAppBar component='nav'>
        <Toolbar
          sx={{
            width: '100%',
            margin: '0 auto',
            backgroundColor: '#1A7EBD',
          }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: 'flex', xs: 'flex', md: 'none' },
              color: '#fff',
              width: '5%',
              justifyContent: 'space-between',
            }}>
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={() => navigate('/')}
            sx={{ display: { xs: 'block', sm: 'none' } }}>
            <img src={logo} alt='logo' width={40} height={40} />
          </IconButton>
          <Typography
            component='div'
            sx={{
              ml: '5px',
              alignItems: 'center',
              display: { xs: 'flex', sm: 'none' },
              color: '#fff',
              fontSize: { xs: '0.8rem', sm: '1rem' },
            }}>
            Aukštaitijos padelio klubas
          </Typography>
          <Box
            component='div'
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'block', md: 'flex' },
            }}>
            <StyledLink to='/'>
              <img src={logo} alt='logo' width={45} height={45} />
            </StyledLink>
            <Typography
              component='div'
              sx={{
                ml: '15px',
                alignItems: 'center',
                display: 'flex',
                color: '#fff',
                fontSize: { xs: '0.8rem', sm: '1rem' },
              }}>
              Aukštaitijos padelio klubas
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            {navmenu.map((item, index) => {
              if (item.role === 'admin' && userRole !== 'admin') {
                return null;
              }
              return (
                <Button
                  key={index}
                  sx={{ color: 'primary.main', marginLeft: '5px' }}>
                  {currentPath !== item.url && (
                    <StyledLink to={item.url}>
                      <ListItemText primary={item.page} />
                    </StyledLink>
                  )}
                  {currentPath === item.url && (
                    <ActiveLink to={item.url}>
                      <ListItemText primary={item.page} />
                    </ActiveLink>
                  )}
                </Button>
              );
            })}
          </Box>

          <IconButton
            color='#ccc'
            sx={{ ml: 'auto' }}
            onClick={() => navigate('/krepselis')}>
            <Badge badgeContent={cartItems.length} color='error'>
              <ShoppingCartIcon />
            </Badge>
            <Typography variant='subtitle1' sx={{ ml: 2, color: '#fff' }}>
              {totalPrice} €
            </Typography>
          </IconButton>
          {userRole === 'admin' && (
            <IconButton
              onClick={() => handleSignOut()}
              sx={{
                ml: '10px',
                alignItems: 'center',
                display: { xs: 'none', sm: 'flex' },
                color: '#3db5ff',
                fontSize: { xs: '0.8rem', sm: '1rem' },
              }}>
              <ListItemText primary='Atsijungti' />
            </IconButton>
          )}
        </Toolbar>
      </StyledAppBar>
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#fff',
            },
          }}>
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
