export const timeTable = [
  { id: 1, start: '07:00', end: '07:30', duration: 30 },
  { id: 2, start: '07:30', end: '08:00', duration: 30 },
  { id: 3, start: '08:00', end: '08:30', duration: 30 },
  { id: 4, start: '08:30', end: '09:00', duration: 30 },
  { id: 5, start: '09:00', end: '09:30', duration: 30 },
  { id: 6, start: '09:30', end: '10:00', duration: 30 },
  { id: 7, start: '10:00', end: '10:30', duration: 30 },
  { id: 8, start: '10:30', end: '11:00', duration: 30 },
  { id: 9, start: '11:00', end: '11:30', duration: 30 },
  { id: 10, start: '11:30', end: '12:00', duration: 30 },
  { id: 11, start: '12:00', end: '12:30', duration: 30 },
  { id: 12, start: '12:30', end: '13:00', duration: 30 },
  { id: 13, start: '13:00', end: '13:30', duration: 30 },
  { id: 14, start: '13:30', end: '14:00', duration: 30 },
  { id: 15, start: '14:00', end: '14:30', duration: 30 },
  { id: 16, start: '14:30', end: '15:00', duration: 30 },
  { id: 17, start: '15:00', end: '15:30', duration: 30 },
  { id: 18, start: '15:30', end: '16:00', duration: 30 },
  { id: 19, start: '16:00', end: '16:30', duration: 30 },
  { id: 20, start: '16:30', end: '17:00', duration: 30 },
  { id: 21, start: '17:00', end: '17:30', duration: 30 },
  { id: 22, start: '17:30', end: '18:00', duration: 30 },
  { id: 23, start: '18:00', end: '18:30', duration: 30 },
  { id: 24, start: '18:30', end: '19:00', duration: 30 },
  { id: 25, start: '19:00', end: '19:30', duration: 30 },
  { id: 26, start: '19:30', end: '20:00', duration: 30 },
  { id: 27, start: '20:00', end: '20:30', duration: 30 },
  { id: 28, start: '20:30', end: '21:00', duration: 30 },
  { id: 29, start: '21:00', end: '21:30', duration: 30 },
  { id: 30, start: '21:30', end: '22:00', duration: 30 },
  { id: 31, start: '22:00', end: '22:30', duration: 30 },
  { id: 32, start: '22:30', end: '23:00', duration: 30 },
];

export const timesUntil17 = timeTable.filter((time) => {
  return parseInt(time.start.split(':')[0]) < 17;
});

export const timesAfter17 = timeTable.filter((time) => {
  return parseInt(time.start.split(':')[0]) >= 17;
});
