import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container, Box, Button, Modal, TextField } from '@mui/material';
import { styled } from '@mui/system';
import Calendar from '../components/Calendar';
import { getTokenFromStorage } from '../services/helpers';
import { deleteBooking, getAdminBookings, updateBooking } from '../services/API';
import { ToastContainer } from 'react-toastify';

const StyledTableCell = styled(TableCell)`
  && {
    text-align: center;
  }
`;

const StyledHeadCell = styled(TableCell)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const Uzsakymai = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [bookings, setBookings] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedSport, setSelectedSport] = useState('all');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [editBookingDate, setEditBookingDate] = useState('');
  const [editBookingStartTime, setEditBookingStartTime] = useState('');
  const [editBookingEndTime, setEditBookingEndTime] = useState('');
  const [editBookingCourt, setEditBookingCourt] = useState('');
  const [editBookingId, setEditBookingId] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const token = getTokenFromStorage();
      const bookings = await getAdminBookings(token);
      setBookings(bookings);
      setLoaded(true);
    };
    fetchBookings();
  }, []);

  const filteredBookings = bookings
    .sort((a, b) => {
      return a.start > b.start ? 1 : -1 && a.courtId > b.courtId ? 1 : -1;
    })
    .filter((booking) => {
      return moment(booking.date).format('YYYY-MM-DD') === selectedDate.format('YYYY-MM-DD');
    })
    .filter((booking) => {
      if (selectedSport === 'all') {
        return true;
      } else {
        return booking.sport === selectedSport;
      }
    });

  const handleSportFilter = (sport) => {
    setSelectedSport(sport);
  };

  const handleCancelBooking = async (bookingId) => {
    const confirmed = window.confirm('Are you sure you want to cancel this booking?');
    if (confirmed) {
      const token = getTokenFromStorage();
      await deleteBooking(token, bookingId);
      const updatedBookings = await getAdminBookings(token);
      setBookings(updatedBookings);
    }
  };

  const handleEditModalOpen = (booking) => {
    setSelectedBooking(booking);
    setEditBookingDate(moment(booking.date).format('YYYY-MM-DD'));
    setEditBookingStartTime(booking.start);
    setEditBookingEndTime(booking.end);
    setEditBookingCourt(booking.courtId);
    setEditBookingId(booking.id);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setSelectedBooking(null);
    setEditModalOpen(false);
  };

  const handleEditBooking = async () => {
    const token = getTokenFromStorage();
    const updatedBooking = {
      ...selectedBooking,
      courtId: editBookingCourt,
      date: editBookingDate,
      start: editBookingStartTime,
      end: editBookingEndTime,
    };
    await updateBooking(token, updatedBooking, editBookingId);
    const updatedBookings = await getAdminBookings(token);
    setBookings(updatedBookings);
    handleEditModalClose();
  };

  if (!loaded) {
    return null;
  }

  return (
    <div>
      <Box sx={{ bgcolor: '#f5f5f5', py: 8, pt: '80px' }}>
        <Container maxWidth='lg'>
          <ToastContainer
            position='top-center'
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Typography
            sx={{
              typography: { xs: 'h4', sm: 'h3' },
            }}
            align='center'
            gutterBottom
          >
            Rezervacijos
          </Typography>
          <Box
            sx={{
              mt: 4,
              mb: 4,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Calendar value={selectedDate} onChange={handleDateChange} />
          </Box>
          <Box sx={{ mt: 4, mb: 4 }}>
            <Typography
              sx={{
                typography: { xs: 'h6', sm: 'h5' },
              }}
              align='center'
              gutterBottom
            >
              Esami užsakymai - {selectedDate.format('YYYY-MM-DD')}
            </Typography>
          </Box>
          <Box
            sx={{
              mb: 4,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Button
              variant={selectedSport === 'all' ? 'contained' : 'outlined'}
              onClick={() => handleSportFilter('all')}
              sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 0 } }}
            >
              Visi
            </Button>
            <Button
              variant={selectedSport === 'Padelis' ? 'contained' : 'outlined'}
              onClick={() => handleSportFilter('Padelis')}
              sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 0 } }}
            >
              Padelis
            </Button>
            <Button
              variant={selectedSport === 'Tinklinis' ? 'contained' : 'outlined'}
              onClick={() => handleSportFilter('Tinklinis')}
              sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 1, sm: 0 } }}
            >
              Tinklinis
            </Button>
            <Button variant={selectedSport === 'beachTennis' ? 'contained' : 'outlined'} onClick={() => handleSportFilter('beachTennis')}>
              Paplūdimio tenisas
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledHeadCell>Sportas</StyledHeadCell>
                  <StyledHeadCell>Kortas</StyledHeadCell>
                  <StyledHeadCell>Pradžia</StyledHeadCell>
                  <StyledHeadCell>Pabaiga</StyledHeadCell>
                  <StyledHeadCell>Klientas</StyledHeadCell>
                  <StyledHeadCell>El.paštas</StyledHeadCell>
                  <StyledHeadCell>Telefono numeris</StyledHeadCell>
                  <StyledHeadCell>Tvarkyti užsakymą</StyledHeadCell>
                  <StyledHeadCell>Redaguoti</StyledHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBookings.map((booking) => (
                  <TableRow key={booking.id}>
                    <StyledTableCell>{booking.sport === 'Beachtennis' ? 'Paplūdimio tenisas' : booking.sport}</StyledTableCell>
                    <StyledTableCell>{booking.courtId}</StyledTableCell>
                    <StyledTableCell>{booking.start}</StyledTableCell>
                    <StyledTableCell>{booking.end}</StyledTableCell>
                    <StyledTableCell>{booking.clientName}</StyledTableCell>
                    <StyledTableCell>{booking.clientEmail}</StyledTableCell>
                    <StyledTableCell>{booking.clientPhone}</StyledTableCell>
                    <StyledTableCell>
                      <Button variant='outlined' onClick={() => handleCancelBooking(booking.id)}>
                        Atšaukti
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button variant='outlined' onClick={() => handleEditModalOpen(booking)}>
                        Redaguoti
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal open={editModalOpen} onClose={handleEditModalClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  typography: { xs: 'body1', sm: 'h6' },
                  mb: 2,
                }}
                gutterBottom
              >
                Redaguoti užsakymą
              </Typography>
              <TextField label='Data' type='date' value={editBookingDate} onChange={(e) => setEditBookingDate(e.target.value)} sx={{ mb: 2 }} />
              <TextField
                label='Pradžia'
                type='time'
                value={editBookingStartTime}
                onChange={(e) => setEditBookingStartTime(e.target.value)}
                sx={{ mb: 2, ml: { xs: 0, sm: 2 } }}
              />
              <TextField
                label='Pabaiga'
                type='time'
                value={editBookingEndTime}
                onChange={(e) => setEditBookingEndTime(e.target.value)}
                sx={{ mb: 2, ml: { xs: 0, sm: 2 } }}
              />
              <TextField
                label='Aikštelės numeris'
                type='number'
                value={editBookingCourt}
                onChange={(e) => setEditBookingCourt(e.target.value)}
                sx={{ mb: 2, ml: { xs: 0, sm: 2 } }}
              />
              <Button variant='contained' onClick={handleEditBooking} sx={{ display: 'flex' }}>
                Išsaugoti
              </Button>
            </Box>
          </Modal>
        </Container>
      </Box>
    </div>
  );
};

export default Uzsakymai;
