import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  Box,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { getAdminCourts, updateCourtPrice } from '../services/API';
import { getTokenFromStorage } from '../services/helpers';

const StyledTableCell = styled(TableCell)`
  && {
    text-align: center;
  }
`;

const StyledHeadCell = styled(TableCell)`
  && {
    text-align: center;
    font-weight: bold;
  }
`;

const StyledPaper = styled(Paper)`
  && {
    padding: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const AdminPrices = () => {
  const [courts, setCourts] = useState([]);
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedCourt, setSelectedCourt] = useState('');
  const [weekdayPriceUntil17, setWeekdayPriceUntil17] = useState('');
  const [weekdayPriceAfter17, setWeekdayPriceAfter17] = useState('');
  const [weekendPriceUntil17, setWeekendPriceUntil17] = useState('');
  const [weekendPriceAfter17, setWeekendPriceAfter17] = useState('');

  useEffect(() => {
    const fetchCourts = async () => {
      const token = getTokenFromStorage();
      const courts = await getAdminCourts(token);
      setCourts(courts);
    };
    fetchCourts();
  }, []);

  const handleSportChange = (event) => {
    setSelectedSport(event.target.value);
    setSelectedCourt('');
    setWeekdayPriceUntil17('');
    setWeekdayPriceAfter17('');
    setWeekendPriceUntil17('');
    setWeekendPriceAfter17('');
  };

  const handleCourtChange = (event) => {
    setSelectedCourt(event.target.value);
    setWeekdayPriceUntil17('');
    setWeekdayPriceAfter17('');
    setWeekendPriceUntil17('');
    setWeekendPriceAfter17('');
  };

  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    if (name === 'weekdayPriceUntil17') {
      setWeekdayPriceUntil17(value);
    } else if (name === 'weekdayPriceAfter17') {
      setWeekdayPriceAfter17(value);
    } else if (name === 'weekendPriceUntil17') {
      setWeekendPriceUntil17(value);
    } else if (name === 'weekendPriceAfter17') {
      setWeekendPriceAfter17(value);
    }
  };

  const handleSubmit = async () => {
    const token = getTokenFromStorage();
    await updateCourtPrice(token, selectedCourt, weekdayPriceUntil17, weekdayPriceAfter17, weekendPriceUntil17, weekendPriceAfter17, selectedSport);
    setWeekdayPriceUntil17('');
    setWeekdayPriceAfter17('');
    setWeekendPriceUntil17('');
    setWeekendPriceAfter17('');
    setSelectedCourt('');
    setSelectedSport('');
    const courts = await getAdminCourts(token);
    setCourts(courts);
  };

  const filteredCourts = courts.filter((court) => {
    if (selectedSport === '') {
      return true;
    } else {
      return court.sport === selectedSport;
    }
  });

  const courtIds = filteredCourts.map((court) => court.courtId);

  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8, pt: '80px' }}>
      <Container maxWidth='md'>
        <ToastContainer
          position='top-center'
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Typography
          sx={{
            typography: { xs: 'h4', sm: 'h3' },
          }}
          align='center'
          gutterBottom
        >
          Kortų kainos
        </Typography>
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField select label='Sportas' value={selectedSport} onChange={handleSportChange} fullWidth>
                <MenuItem value='Padelis'>Padelis</MenuItem>
                <MenuItem value='Tinklinis'>Tinklinis</MenuItem>
                <MenuItem value='Beachtennis'>Paplūdimio tenisas</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField select label='Aikštelės numeris' value={selectedCourt} onChange={handleCourtChange} fullWidth disabled={selectedSport === ''}>
                {courtIds.map((courtId) => (
                  <MenuItem key={courtId} value={courtId}>
                    {courtId}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='Darbo dienos kaina iki 17h'
                name='weekdayPriceUntil17'
                value={weekdayPriceUntil17}
                onChange={handlePriceChange}
                disabled={selectedCourt === '' || selectedSport === ''}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='Darbo dienos kaina po 17h'
                name='weekdayPriceAfter17'
                value={weekdayPriceAfter17}
                onChange={handlePriceChange}
                disabled={selectedCourt === '' || selectedSport === ''}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='Savaitgalio kaina iki 17h'
                name='weekendPriceUntil17'
                value={weekendPriceUntil17}
                onChange={handlePriceChange}
                disabled={selectedCourt === '' || selectedSport === ''}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='Savaitgalio kaina po 17h'
                name='weekendPriceAfter17'
                value={weekendPriceAfter17}
                onChange={handlePriceChange}
                disabled={selectedCourt === '' || selectedSport === ''}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant='contained'
                color='primary'
                disabled={selectedCourt === '' || selectedSport === ''}
                sx={{
                  mt: { xs: 2, sm: 0 },
                  height: { xs: 50, sm: '100%' },
                }}
                onClick={() => handleSubmit()}
              >
                Išsaugoti
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
        <TableContainer
          component={Paper}
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Table
            sx={{
              textAlign: 'center',
            }}
          >
            <TableHead>
              <TableRow>
                <StyledHeadCell>Sportas</StyledHeadCell>
                <StyledHeadCell>Kortas</StyledHeadCell>
                <StyledHeadCell>I-V 07-17</StyledHeadCell>
                <StyledHeadCell>I-V 17-23</StyledHeadCell>
                <StyledHeadCell>VI-VII 07-17</StyledHeadCell>
                <StyledHeadCell>VI-VII 17-23</StyledHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courts.map((booking) => (
                <TableRow key={booking.id}>
                  <StyledTableCell>{booking.sport === 'Beachtennis' ? 'Paplūdimio tenisas' : booking.sport}</StyledTableCell>
                  <StyledTableCell>{booking.courtName}</StyledTableCell>
                  <StyledTableCell>{Number(booking.weekdayPriceUntil17)} €</StyledTableCell>
                  <StyledTableCell>{Number(booking.weekdayPriceAfter17)} €</StyledTableCell>
                  <StyledTableCell>{Number(booking.weekendPriceUntil17)} €</StyledTableCell>
                  <StyledTableCell>{Number(booking.weekendPriceAfter17)} €</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default AdminPrices;
