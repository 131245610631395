import React, { useContext, useState, useEffect, useCallback } from 'react';
import { CartContext } from '../context/CartContext';
import { Box, Button, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import getStripe from '../services/stripe';
import moment from 'moment';
import { getUserRoleFromToken } from '../services/helpers';
import { getAllBookings, postBooking } from '../services/API';
import { ToastContainer } from 'react-toastify';

const Krepselis = () => {
  const { cartItems, removeCartItem, clearCart } = useContext(CartContext);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [session, setSession] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const [formValid, setFormValid] = useState(false);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const bookingItems = JSON.parse(localStorage.getItem('cartItems'));
  const clientFullName = localStorage.getItem('clientName');
  const clientEmailAddress = localStorage.getItem('clientEmail');
  const clientPhoneNumber = localStorage.getItem('clientPhone');
  const userRole = getUserRoleFromToken();

  useEffect(() => {
    setCartItemCount(cartItems.length);
  }, [cartItems]);

  const handleNameChange = (event) => {
    const name = event.target.value;
    setClientName(name);
    setNameTouched(true);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setClientEmail(email);
    setEmailTouched(true);
  };

  const handlePhoneChange = (event) => {
    const phone = event.target.value;
    setClientPhone(phone);
    setPhoneTouched(true);
  };

  const handleRemoveCartItem = (index) => {
    removeCartItem(index);
  };

  const handleReservationPost = useCallback(async () => {
    const response = await postBooking({
      cartItems: bookingItems,
      clientName: userRole === 'admin' ? clientName : clientFullName,
      clientEmail: userRole === 'admin' ? clientEmail : clientEmailAddress,
      clientPhone: userRole === 'admin' ? clientPhone : clientPhoneNumber,
    });
    if (response.status === 'SUCCESS') {
      clearCart();
      setClientEmail('');
      setClientName('');
      setNameError('');
      setEmailError('');
      setPhoneError('');
      setClientPhone('');
      setPhoneTouched(false);
      setEmailTouched(false);
      setNameTouched(false);
      setFormValid(false);
      localStorage.removeItem('cartItems');
      localStorage.removeItem('clientName');
      localStorage.removeItem('clientEmail');
      localStorage.removeItem('clientPhone');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  }, [
    bookingItems,
    clientEmailAddress,
    clientFullName,
    clientPhoneNumber,
    clearCart,
    setEmailError,
    setNameError,
    setPhoneError,
    setFormValid,
    setClientEmail,
    setClientName,
    setClientPhone,
    setEmailTouched,
    setNameTouched,
    setPhoneTouched,
    clientEmail,
    clientName,
    clientPhone,
    userRole,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    const fetchCheckoutData = async () => {
      const response = await fetch(`https://api.stripe.com/v1/checkout/sessions/${sessionId}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
      });
      const data = await response.json();
      setCheckoutData(data);
    };

    fetchCheckoutData();
  }, []);

  useEffect(() => {
    if (checkoutData && checkoutData.payment_status === 'paid') {
      handleReservationPost();
    } else if (checkoutData && checkoutData.payment_status === 'unpaid') {
      alert('Mokėjimas nepavyko');
    }
  }, [checkoutData]);

  const handleCheckout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    console.warn(error.message);
  };

  const handleReservation = async () => {
    const allBookings = await getAllBookings();
    const bookedItems = allBookings.filter((item) =>
      cartItems.some(
        (cartItem) =>
          cartItem.date === item.date &&
          cartItem.start === item.start &&
          cartItem.end === item.end &&
          cartItem.courtId === item.courtId &&
          cartItem.sport === item.sport
      )
    );

    const available = bookedItems.length === 0;

    if (!available) {
      const bookedItemNames = bookedItems.map((item) => `${item.sport} ${item.start} - ${item.end}`).join(', ');
      alert(`Šis laikas jau yra rezervuotas: ${bookedItemNames}`);
      return;
    }

    const currentTime = moment().format('HH:mm');
    const currentDay = moment().format('YYYY-MM-DD');
    const invalidStartTime = cartItems.some((item) => item.start < currentTime && item.date <= currentDay);

    if (invalidStartTime) {
      alert('Vienas iš pasirinktų laikų yra praeityje');
      return;
    }

    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    localStorage.setItem('clientName', clientName);
    localStorage.setItem('clientEmail', clientEmail);
    localStorage.setItem('clientPhone', clientPhone);
    userRole === 'admin' ? handleReservationPost() : handleCheckout();
  };

  function buildQuery(data, prefix) {
    var type = Object.prototype.toString.call(data).slice(8, -1).toLowerCase();
    return Object.keys(data)
      .map(function (key, index) {
        var value = data[key];
        if (type === 'array') {
          key = prefix + '[' + index + ']';
        } else if (type === 'object') {
          key = prefix ? prefix + '[' + key + ']' : key;
        }

        if (typeof value === 'object') {
          return buildQuery(value, key);
        }

        return key + '=' + encodeURIComponent(value);
      })
      .join('&');
  }

  useEffect(() => {
    async function getStripeSession() {
      const totalPrice = cartItems.reduce((total, item) => total + Number(item.price), 0);

      let stripeRequest = await fetch('https://api.stripe.com/v1/checkout/sessions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          'Content-type': 'application/x-www-form-urlencoded',
        },
        body: buildQuery({
          payment_method_types: ['card'],
          mode: 'payment',
          success_url: `${process.env.REACT_APP_URL}/krepselis?session_id={CHECKOUT_SESSION_ID}`,
          cancel_url: `${process.env.REACT_APP_URL}/krepselis`,
          customer_email: clientEmail,
          client_reference_id: clientEmail,
          allow_promotion_codes: true,
          line_items: [
            {
              price_data: {
                currency: 'eur',
                unit_amount: totalPrice * 100, // Convert to cents
                product_data: {
                  name: 'Kortų rezervacija',
                },
              },
              quantity: 1,
            },
          ],
        }),
      });
      let stripeResponse = await stripeRequest.json();
      setSession(stripeResponse);
    }
    getStripeSession();
  }, [cartItems, clientEmail]);

  const handleClearCart = () => {
    clearCart();
  };

  useEffect(() => {
    if (nameTouched && clientName.split(' ').length >= 2) {
      setNameError('');
    } else if (nameTouched) {
      setNameError('Įveskite vardą ir pavardę');
      setFormValid(false);
    }

    if (emailTouched && clientEmail.includes('@')) {
      setEmailError('');
    } else if (emailTouched) {
      setEmailError('Įveskite el.paštą');
      setFormValid(false);
    }

    if (phoneTouched && clientPhone.length >= 9) {
      setPhoneError('');
    } else if (phoneTouched) {
      setPhoneError('Įveskite telefono numerį');
      setFormValid(false);
    }
  }, [clientName, clientEmail, clientPhone, nameTouched, emailTouched, phoneTouched]);

  useEffect(() => {
    setFormValid(nameTouched && emailTouched && phoneTouched && nameError === '' && emailError === '' && phoneError === '' && cartItemCount > 0);
  }, [nameTouched, emailTouched, nameError, emailError, cartItemCount, phoneTouched, phoneError]);

  const totalPrice = cartItems.reduce((total, item) => total + Number(item.price), 0);
  const totalDuration = cartItems.reduce((total, item) => total + item.duration, 0);

  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8, pt: '80px', height: '100%' }}>
      <Container maxWidth='md'>
        <ToastContainer
          position='top-center'
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Typography
          sx={{
            typography: { xs: 'h4', sm: 'h3' },
          }}
          align='center'
          gutterBottom
        >
          Krepšelis
        </Typography>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} md={8}>
            {cartItems.length === 0 ? (
              <Typography variant='body1' align='center'>
                Jūsų krepšelis tuščias
              </Typography>
            ) : (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                {cartItems
                  .sort((a, b) => {
                    return a.start > b.start ? 1 : -1 && a.courtId > b.courtId ? 1 : -1;
                  })
                  .map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        bgcolor: '#fff',
                        borderRadius: '5px',
                        p: 1,
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{
                          flex: 1,
                          fontSize: { xs: '0.8rem', sm: '1rem' },
                          textAlign: { xs: 'left', sm: 'center' },
                        }}
                      >
                        {item.sport === 'Beachtennis' ? 'Paplūdimio tenisas' : item.sport}
                      </Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: { xs: '0.8rem', sm: '1rem' },
                          flex: 1,
                          textAlign: { xs: 'left', sm: 'center' },
                        }}
                      >
                        {item.courtName}
                      </Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: { xs: '0.8rem', sm: '1rem' },
                          flex: 1,
                          textAlign: { xs: 'left', sm: 'center' },
                        }}
                      >
                        {item.date}
                      </Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: { xs: '0.8rem', sm: '1rem' },
                          flex: 1,
                          textAlign: { xs: 'left', sm: 'center' },
                        }}
                      >
                        {item.start} - {item.end}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant='body1'
                          sx={{
                            fontSize: { xs: '0.8rem', sm: '1rem' },
                            flex: 1,
                            textAlign: { xs: 'left', sm: 'center' },
                            mr: 1,
                          }}
                        >
                          {Number(item.price)} €
                        </Typography>
                        <IconButton aria-label='delete' onClick={() => handleRemoveCartItem(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#fff',
                    borderRadius: '5px',
                    p: 2,
                    mt: 2,
                  }}
                >
                  <Typography variant='body1'>Total:</Typography>
                  <Typography variant='body1'>
                    {totalDuration}min. {totalPrice} €
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button variant='contained' color='primary' onClick={handleClearCart}>
                    Išvalyti krepšelį
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ bgcolor: '#fff', borderRadius: '5px', p: 2 }}>
              <Typography variant='h6' gutterBottom>
                Jūsų informacija
              </Typography>
              <TextField
                label='Vardas Pavardė'
                variant='outlined'
                fullWidth
                margin='normal'
                value={clientName}
                onChange={handleNameChange}
                required
                error={nameError !== ''}
                helperText={nameTouched ? nameError : ''}
              />
              <TextField
                label='El.paštas'
                variant='outlined'
                fullWidth
                margin='normal'
                value={clientEmail}
                onChange={handleEmailChange}
                required
                error={emailError !== ''}
                helperText={emailTouched ? emailError : ''}
              />
              <TextField
                label='Telefono numeris'
                variant='outlined'
                fullWidth
                margin='normal'
                value={clientPhone}
                onChange={handlePhoneChange}
                required
                type='number'
                error={phoneError !== ''}
                helperText={phoneTouched ? phoneError : ''}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant='contained' color='primary' onClick={handleReservation} disabled={userRole === 'admin' ? false : !formValid}>
                Užsakyti
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Krepselis;
